.flex {
  display: flex;
}
.flex.column {
  flex-direction: column;
}

.flex.align-center {
  align-items: center;
}
.flex.align-start {
  align-items: start;
}
.flex.align-end {
  align-items: end;
}
.flex.align-stretch {
  align-items: stretch;
}

.flex.justify-center {
  justify-content: center;
}
.flex.justify-start {
  justify-content: start;
}
.flex.justify-end {
  justify-content: end;
}
.flex.justify-space-between {
  justify-content: space-between;
}
.flex.justify-space-around {
  justify-content: space-around;
}
.flex.justify-space-evenly {
  justify-content: space-evenly;
}

.flex.gap-small {
  gap: 0.5rem;
}
.flex.gap-middle {
  gap: 1rem;
}
.flex.gap-large {
  gap: 1.5rem;
}

.flex.wrap-wrap {
  flex-wrap: wrap;
}
.flex.wrap-wrap-reverse {
  flex-wrap: wrap-reverse;
}
